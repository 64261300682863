import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { BlogContext } from "./BlogContext"
import './n.css'; // Import the CSS file
import Back from "../common/back/Back"

const BlogLayout = () => {
  const { id } = useParams();
  const { blogs, loading, error } = useContext(BlogContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const blog = blogs.find((blog) => blog.id === parseInt(id));

  if (!blog) {
    return <div>Blog not found</div>;
  }

  // Sort blogs by posted_date in descending order
  const sortedBlogs = [...blogs].sort((a, b) => new Date(b.posted_date) - new Date(a.posted_date));
  const latestBlog = sortedBlogs[1];

  return (
    <div className="rows">
      <Back title='Individual Posts' />
      <div className="leftcolumn">
        <div className="card">
          <div style={{display:'flex',justifyContent:'space-between'}}>
          <h2 style={{color:'#97022c'}}>{blog.blog_title}</h2>
          <h3 style={{color:'#97022c'}}>{new Date(blog.posted_date).toLocaleDateString()}</h3>
          </div><br />
          <div >
          <div className="fakeimg">
            <img src={`https://myedudiary.com/backend/api${blog.blog_image}`} alt={blog.blog_title} loading="lazy" className='imgblog' />
          </div> <br /><br />
          <p>{blog.blog_content}</p> <br /><br />
          </div>
        </div>
      </div>
      <div className="rightcolumn">
        <div className="card">
          <h2>Latest</h2>
          {latestBlog ? (
            <div style={{display:'flex',marginTop:'10px'}}>
              <div className="fakeimg">
                <img src={`https://myedudiary.com/backend/api${latestBlog.blog_image}`} alt={latestBlog.blog_title} loading="lazy" style={{ height: '100px',borderRadius:'20px' }} />
              </div>
              <h3 style={{display:'flex',alignItems:'center',marginLeft:'20px'}}>{latestBlog.blog_title}</h3>
              {/* <p>{new Date(latestBlog.posted_date).toLocaleDateString()}</p> */}
              {/* <p>{latestBlog.blog_content.substring(0, 100)}...</p> */}
            </div>
          ) : (
            <p>No latest blog found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogLayout;
