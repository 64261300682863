import React, { useEffect, useState } from "react";
import axios from "axios";
import Masonry from "react-masonry-css";
import "./Gallery.css"; // Import a CSS file for styling
import "./Photo.css";
import View from "./View";

const Photo = () => {
  const [images, setImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");

  let imgCount = images.length;
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          "https://myedudiary.com/backend/api/academic/"
        );
        setImages(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  function handleSelection(index) {
    setSelected(index);
    setIsOpen(true);
  }
  function handleClose(e) {
    if (e.target.classList.contains("view-overlay")) {
      setSelected("");
      setIsOpen(false);
    }
  }
  function handleNext() {
    if (selected === imgCount - 1) return;

    setSelected((cur) => cur + 1);
  }
  function handlePrev() {
    if (selected <= 0) return;
    setSelected((cur) => cur - 1);
  }
  return (
    <div className="photo-container">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, i) => (
          <div key={image.id}>
            <img
              onClick={() => handleSelection(i)}
              src={`https://myedudiary.com/backend/api${image.image}`}
              alt={`Gallery ${image.id}`}
              style={{ height: "auto" }}
            />
          </div>
        ))}
      </Masonry>
      {isOpen && (
        <View
          image={images[selected]}
          onClose={handleClose}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      )}
    </div>
  );
};

export default Photo;
