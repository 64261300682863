import React, { useEffect, useState } from "react";
import Back from "../common/back/Back";
import TeamCard from "./TeamCard";
import "./team.css";
import Awrapper from "../about/Awrapper";
import "../about/about.css";

const Team = () => {
  const [members, setMembers] = useState([]);
  console.log(members);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await fetch(
          "https://myedudiary.com/backend/api/team_members/"
        );
        const data = await response.json();
        setMembers(data);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchTeamMembers();
  }, []);

  return (
    <>
      <Back title="Team" />
      <section className="team padding">
        <div className="container grid grid1">
          <TeamCard members={members} />
        </div>
      </section>
      {/* <Awrapper /> */}
    </>
  );
};

export default Team;
