import React from "react";
import "./View.css";

export default function View({ image, onClose, onNext, onPrev }) {
  return (
    <div className="view-overlay" onClick={onClose}>
      <div className="view-wrapper">
        <div className="view-btn btn-prev" onClick={onPrev}>
          <i class="fa-solid fa-angle-left"></i>
        </div>
        <img
          className="view-image"
          src={`https://myedudiary.com/backend/api${image.image}`}
          alt={`Gallery ${image.id}`}
          style={{ maxHeight: "80vh", width: "auto" }}
        />
        <div className="view-btn btn-next" onClick={onNext}>
          <i class="fa-solid fa-angle-right"></i>
        </div>
      </div>
    </div>
  );
}
