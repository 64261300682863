import React, { useContext } from "react";
import { BlogContext } from "./BlogContext";
import { Link } from "react-router-dom";

const BlogCard = () => {
  const { blogs, loading, error } = useContext(BlogContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {blogs.map((val) => (
        <div className="items shadow" key={val.id}>
          <div className="img">
            <img
              src={`https://myedudiary.com/backend/api${val.blog_image}`}
              alt={val.blog_title}
              loading="lazy"
            />
          </div>
          <div className="text">
            <div className="admin flexSB">
              <span>
                <i className="fa fa-user"></i>
                <label>{val.type || "Admin"}</label>
              </span>
              <span>
                <i className="fa fa-calendar-alt"></i>
                <label>{new Date(val.posted_date).toLocaleDateString()}</label>
              </span>
            </div>
            <h1>{val.blog_title}</h1>
            <p>{val.blog_content}</p>
            <p>
              <Link to={`/blog/${val.id}`}>read more...</Link>
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default React.memo(BlogCard);
