import React, { useEffect, useState } from "react";
import "./courses.css";
import axios from "axios";
import '../home/l.css'

const CoursesCard = () => {
  const [courses, setCourses] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    contact_no: "",
  });

  useEffect(() => {
    axios
      .get("https://myedudiary.com/backend/api/course_material/")
      .then((response) => {
        setCourses(response.data);
      })
      .catch((error) => {
        console.error("Error fetching the courses:", error);
      });
  }, []);

  const handleOpenModal = (course) => {
    setSelectedCourse(course);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const payload = {
      ...formData,
      course: selectedCourse?.name, // Add course name to the form data
    };

    // Post data to the API
    axios
      .post("https://myedudiary.com/backend/api/courseform/", payload)
      .then((response) => {
        console.log("Form submitted successfully", response.data);
        setModalOpen(false); // Close the modal after successful submission
        setFormData({
          name: "",
          email: "",
          description: "",
          contact_no: "",
        }); // Reset form data
      })
      .catch((error) => {
        console.error("Error submitting the form:", error);
      });
  };

  return (
    <>
      <section className="coursesCard">
        <div className="container grid2 grid1">
          {courses.length > 0 ? (
            courses.map((course) => (
              <div className="items" key={course.id}>
                <div className="content flex">
                  <div className="left">
                    <div className="img" style={{ display: 'flex' }}>
                      <img
                        src="https://img.icons8.com/dotty/80/000000/language.png"
                        alt={course.name}
                        style={{ filter: 'invert(100%)', display: 'flex' }}
                      />
                    </div>
                    <div className="header">
                      <h1>{course.name}</h1>

                    </div>
                  </div>
                  <div className="text">
                    <div className="rate">
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <label>(5.0)</label>
                    </div>
                    <div className="course-info flex" style={{ marginBottom: '15px' }}>
                      <div className="total-hours" style={{ marginRight: '20px' }}>
                        <span className="badge" style={{ backgroundColor: '#1eb2a6', padding: '5px 10px', borderRadius: '5px', color: '#fff', fontWeight: 'bold' }}>
                          {course.total_hours} Hours
                        </span>
                      </div>
                      <div className="total-days">
                        <span className="badge" style={{ backgroundColor: '#1eb2a6', padding: '5px 10px', borderRadius: '5px', color: '#fff', fontWeight: 'bold' }}>
                          {course.total_days} Days
                        </span>
                      </div>
                    </div>
                    <p className="description">
                      {course.description}
                    </p>
                    <div className="course-points" style={{ marginTop: '15px' }}>
                      <h3 style={{ color: '#1eb2a6', marginBottom: '10px' }}>What you'll learn:</h3>
                      <ul style={{ paddingLeft: '20px' }}>
                        {course.learning_points && course.learning_points.length > 0 ? (
                          course.learning_points.map((point, index) => (
                            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                              <i className="fa fa-check-circle" style={{ color: '#1eb2a6', marginRight: '10px' }}></i>
                              {point}
                            </li>
                          ))
                        ) : (
                          <li>No learning points available</li>
                        )}
                      </ul>
                    </div>
                    {/* <div className="details">
                      <h4>Taught by: {course.teach_by}</h4>
                    </div> <br /> */}
                    <br />
                    <button
                      className="outline-btn"
                      onClick={() => handleOpenModal(course)}
                    >
                      ENROLL NOW!
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Loading courses...</p>
          )}
        </div>
      </section>

      {/* Modal */}
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Enroll in {selectedCourse?.name}</h2>
            <div className="form-group">
              <label>Name:</label>
              <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input type="email" name="email" value={formData.email} onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label>Description:</label>
              <textarea name="description" value={formData.description} onChange={handleInputChange}></textarea>
            </div>
            <div className="form-group">
              <label>Contact No:</label>
              <input type="text" name="contact_no" value={formData.contact_no} onChange={handleInputChange} />
            </div>
            <button className="submit-btn" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      )}
    </>
  );
};

export default CoursesCard;
