import React, { useContext } from "react";
import "./footer.css";
import { BlogContext } from "../../blog/BlogContext";
import { ContactContext } from "../../contact/ContactContext";
import Newsletter from "./Mail";
import { Link } from "react-router-dom";

const Footer = () => {
  const {
    blogs,
    loading: blogsLoading,
    error: blogsError,
  } = useContext(BlogContext);
  const {
    contacts,
    loading: contactsLoading,
    error: contactsError,
  } = useContext(ContactContext);

  if (blogsLoading || contactsLoading) {
    return <div>Loading...</div>;
  }

  if (blogsError || contactsError) {
    return <div>Error: {blogsError || contactsError}</div>;
  }

  return (
    <>
      <section className="newletter">
        <Newsletter />
      </section>
      <footer>
        <div className="container padding grid1 ">
          <div className="box ">
            <h1>Bils</h1>
            <span>Learn German Language</span>
            <p>
              Bethany Institute for Language Studies (BILS), Thiruvalla, offers
              a seamless and affordable pathway to mastering the German
              language. Our students consistently achieve excellent results,
              especially at the B2 level.
            </p>
            <a
              href="https://www.facebook.com/bethany.institute.for.language.studies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f icon"></i>
            </a>
            <a
              href="https://www.instagram.com/learngermaninbils?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram icon"></i>
            </a>
            <a
              href="http://www.youtube.com/@BILS-BethanylanguageSchool"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube icon"></i>
            </a>{" "}
          </div>
          <div className="box link">
            <h3>Explore</h3>
            <ul>
              <li>
                <Link to="/courses" style={{ color: "black" }}>
                  All Course
                </Link>
              </li>
              <li>
                <Link to="/team" style={{ color: "black" }}>
                  Team
                </Link>
              </li>
              <li>
                <Link to="/gallery" style={{ color: "black" }}>
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/news" style={{ color: "black" }}>
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact" style={{ color: "black" }}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="box link c" style={{ visibility: "hidden" }}>
            <h3>Quick Links</h3>
            <ul>
              <li>Contact Us</li>
              <li>Terms & Conditions</li>
              <li>Privacy</li>
              <li>Feedbacks</li>
              <li>Home</li>
            </ul>
          </div>
          <div className="box c" style={{ visibility: "hidden" }}>
            <h3>Recent Post</h3>
            {blogs &&
              blogs.slice(0, 3).map((val, index) => (
                <div
                  className="items flexSB"
                  style={{ justifyContent: "flex-start" }}
                  key={index}
                >
                  <div className="img">
                    <img
                      src={`https://myedudiary.com/backend/api${val.blog_image}`}
                      alt={val.blog_title}
                    />
                  </div>
                  <div className="text">
                    <span>
                      <i className="fa fa-calendar-alt"></i>
                      <label>
                        {new Date(val.posted_date).toLocaleDateString()}
                      </label>
                    </span>
                    <span>
                      <i className="fa fa-user"></i>
                      <label>{val.type || "Admin"}</label>
                    </span>
                    <h4>{val.blog_title.slice(0, 40)}...</h4>
                  </div>
                </div>
              ))}
          </div>
          <div className="box last">
            <h3>Have a Questions?</h3>
            <ul>
              {contacts &&
                contacts.map((val, index) => (
                  <li key={index}>
                    <i className="fa fa-map"></i> {val.address}
                  </li>
                ))}
              {contacts &&
                contacts.map((val, index) => (
                  <li key={index}>
                    <i className="fa fa-phone-alt"></i> {val.phone_number_one}
                  </li>
                ))}
              {contacts &&
                contacts.map((val, index) => (
                  <li key={index}>
                    <i className="fa fa-paper-plane"></i> {val.email}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>
          Copyright ©2024 All rights reserved | Design by
          <a
            href="https://buzcatch.com"
            style={{ color: "black" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Buzcatch
          </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
