import React, { useEffect, useState } from "react";
import Heading from "../../common/heading/Heading";
import "./style.css";

const Testimonal = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    // Fetch testimonials from the API
    const fetchTestimonials = async () => {
      try {
        const response = await fetch("https://myedudiary.com/backend/api/testimonials/");
        const data = await response.json();
        setTestimonials(data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <section className='testimonal padding'>
      <div className='container'>
        <Heading subtitle='TESTIMONIAL' title='Our Successful Students' />

        <div className='content grid2 grid1'>
          {testimonials.map((val, index) => (
            <div className='items shadow' key={index}>
              <div className='box flex'>
                <div className='img'>
                  <img src={`https://myedudiary.com/backend/api${val.image}`} alt={val.name} />
                  <i className='fa fa-quote-left icon'></i>
                </div>
                <div className='name'>
                  <h2>{val.name}</h2>
                  <span>{val.designation}</span>
                </div>
              </div>
              <p>{val.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonal;
